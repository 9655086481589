<template>
    <div>
        <MediaRenderer
         :media="media"
         title="Preview"
        />
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import MediaRenderer from '../MediaRenderer/MediaRenderer.vue'

export default defineComponent({
    components: { 
        MediaRenderer 
    },
    name: 'MediaPreviewModal',
    props: {
        media: {
            type: Object,
            required: true,
        },
    },
    setup() {
        
    },
    async created() {
        //console.log('MediaPreviewModal Created!!')
    }
})
</script>

<style scoped>

</style>